import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, DetachedRouteHandle } from "@angular/router";

/*
Strategy to reuse routes based on a route defining a "reuseKey" in its data. See below for usage.
This approach will work with lazy loaded modules.

In app module under providers:
{
  provide: RouteReuseStrategy,
  useFactory: () => new RouteDataReuseStrategy
}

In any routing module:
{
  path: 'foo',
  data: { reuseKey: 'whatever' }
}

**/
export class RouteDataReuseStrategy extends BaseRouteReuseStrategy {
  private readonly KeyName: string = 'reuseKey';
  private readonly storedRouteHandles = new Map<string, DetachedRouteHandle>();

  // Determines if the route should be detached for reuse (if true, angular will call store())
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const key = route.data[this.KeyName];
    return String.isNotEmpty(key);
  }

  // Stores the detached route. If null is passed, the previously set handle should be cleared
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const key = route.data[this.KeyName];
    if (String.isNotEmpty(key)) {
      // note that there is some discussion on the internets as to whether previously cached components are getting destroyed
      // may need to keep an eye on it and manually call destroy with replacing a value
      this.storedRouteHandles.set(key, handle);
    }
  }

  // Returns true if we have a stored handle for the route
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = route.data[this.KeyName];
    return String.isNotEmpty(key) && this.storedRouteHandles.has(key);
  }

  // Returns the previously stored handle for the route
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const key = route.data[this.KeyName];
    return this.storedRouteHandles.get(key);
  }
}