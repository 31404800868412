import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Debug } from '@app/core';
import { AutoCancelHttpService } from '@app/core/auto-cancel-http.service';
import { environment } from '@env';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private readonly http: HttpClient,
    private readonly cancel: AutoCancelHttpService
  ) { }

  /**
   * Submits password reset info and returns full response data
   * @param resetDetails: @see PasswordResetDetails
   * @returns Promise HttpResponse
   */
  resetPassword(resetDetails: PasswordResetDetails) {
    Debug.debug(`[AccountService:resetPassword] Submitting password data for ${resetDetails.email}`);
    return firstValueFrom(this.http.post<null>(
      [environment.securityApiUri, 'Password/ForgottenPassword'].join(''),
      resetDetails,
      { observe: 'response', withCredentials: false }
    ));
  }

  /**
  * Fetches the password reset access token (if link is still valid)
  * @param request: @see PasswordChangeTokenRequest
  * @returns Promise HttpResponse
  */
  validateToken(request: PasswordChangeTokenRequest) {
    Debug.debug('[AccountService:validateToken] Validating reset token');
    return this.cancel.post<PasswordChangeTokenResponse>(
      [environment.portalApiUri, 'api/passwordreset/validate'].join(''),
      request,
      false
    );
  }

  /**
   * Submits password set info and returns full response data
   * @param setDetails: @see SetPasswordRequest
   * @returns Promise HttpResponse
   */
  setPassword(setDetails: SetPasswordRequest, accessToken: string) {
    Debug.debug(`[AccountService:setPassword] Submitting password data for ${setDetails.email}`);
    return firstValueFrom(this.http.post<null>(
      [environment.securityApiUri, 'Password/Change'].join(''),
      setDetails,
      { observe: 'response', headers: new HttpHeaders({ Authorization: `Bearer ${accessToken}` }) }
    ));
  }
}

export interface SetPasswordRequest {
  email: string;
  password: string;
  token: string;
  resetCode: string;
}

export interface PasswordChangeTokenRequest {
  email: string;
  token: string;
}

export interface PasswordChangeTokenResponse {
  accessToken: string;
}

export interface PasswordResetDetails {
  email: string;
}