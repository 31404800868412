import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SuspendedMessageModalComponent } from '@app/retailer/suspended-message-modal/suspended-message-modal.component';
import { CoreModule } from '@core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BackgroundImageDirective } from './background-image.directive';
import { CredentialModalComponent, ImpersonationHeaderComponent, SsoModalComponent } from './impersonation-header';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { PageFooterLogoComponent } from './page-footer-logo/page-footer-logo.component';
import { PageFooterSocialMediaComponent } from './page-footer-social-media/page-footer-social-media.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageLayoutService } from './page-layout.service';
import { PageLayoutComponent } from './page-layout/page-layout.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    NgbModule
  ],
  exports: [
    PageLayoutComponent,
    LoginLayoutComponent,
    BackgroundImageDirective
  ],
  declarations: [
    PageLayoutComponent,
    LoginLayoutComponent,
    PageFooterComponent,
    PageFooterSocialMediaComponent,
    ImpersonationHeaderComponent,
    PageFooterLogoComponent,
    SuspendedMessageModalComponent,
    SsoModalComponent,
    CredentialModalComponent,
    BackgroundImageDirective
  ],
  providers: [
    PageLayoutService
  ]
})
export class PageLayoutModule { }
