// STAGING: ng serve -c=stage
export const environment = {
  production: true, // Enables enhanced security and disables most console logging
  showDevBar: false, // Enables devBar
  pollProfileService: true,
  bypassAuth: false, // For local testing (helpful for styling and component design)
  useLocalData: false, // Bypasses remote API fetches and returns local json data (located in the assets/spec folder)
  locale: 'en-US',
  enableImageRightWeb: false, // Enable or hide links to ImageRight Web
  enableProducerfeatures: true, // Enable or hide producer web
  endImpersonationWithCiam: true,
  cookieEncryptKey: ',R=}E/1CyH8L4Rc,Cvg3}6t1RnMCdw',

  /**
   * Enables debugging taps, console logging, and additional debugging information in modals. A
   * lower number is more verbose and messages with a level at or above this value will be shown.
   * Levels: disable = 0,  debug = 1,  log = 2,  info = 3,  warn = 4,  error = 5,  critical = 6
   */
  debugLevel: 2,

  /* deprecated uris */
  aimBindingApiUri: 'https://mystage.rpsins.com/rpsaimbinding/api/',
  claimsUri: 'https://mystage.rpsins.com/ClaimsFNOL/',
  cmpUri: 'https://mystage.rpsins.com/CMP/',
  geoApiUri: 'https://mystage.rpsins.com/geo/api/',
  portalApiUri: 'https://mystage.rpsins.com/portal/',
  producersApiUri: 'https://mystage.rpsins.com/producers/api/',
  productApiUri: 'https://mystage.rpsins.com/products/api/',
  rampProducerLookupUri: 'https://mystage.rpsins.com/RAMP/Admin/ProducerLookup.aspx',
  rampAdminHomeUri: 'https://mystage.rpsins.com/RAMP/Admin/Default.aspx',
  rampUri: 'https://mystage.rpsins.com/RAMP/',
  rateLinksUri: 'https://mystage.rpsins.com/ratelinks/',

  /* uris */
  niprApiUri: 'https://apistage.rpsins.com/nipr2/api/',
  directoryApiUri: 'https://apistage.rpsins.com/rps-directory-service/v1',
  contentApiUri: 'https://apistage.rpsins.com/rps-content-service/v1',
  catalogApiUri: 'https://apistage.rpsins.com/rps-catalog-service/v2',
  myrpsUri: 'https://mystage.rpsins.com',
  aimApiUri: 'https://apistage.rpsins.com/aim/',
  documentApiUri: 'https://apistage.rpsins.com/document/',
  statementApiUri: 'https://apistage.rpsins.com/statement/',
  certificateApiUri: 'https://apistage.rpsins.com/certificate/',
  ePayUri: 'https://rpsins.epaypolicydemo.com/',
  rpsInsightsDashboard: 'https://app.powerbi.com/groups/fcc4a472-23e9-4847-8559-f96a87d072df/reports/0308d2f9-e3d2-496c-8d94-2351f0a0be9e/ReportSection28838e67094d614e9d11?experience=power-bi&clientSideAuth=0',
  imageRightWeb: 'https://rpsushc2ir75vs.amer.ajgco.com/imageright.web.client/',
  securityApiUri: 'https://mystage.rpsins.com/security/api/',
  useLocalOnboarding: false, // Since onboarding uri is fetched from directory-service, this enables dev-bar support

  /* keys n stuff */
  googleApiKey: 'AIzaSyD2s8yvM1crjTB1UOEhtOMBOWSGZE9L3UI',
  captchaSiteKey: '6Ldz5WQUAAAAAI2SVxA6cV2hOuv4cpCLQHtmw9s_',
  clientId: 'myrps',

  /* hellosign */
  helloSignClientId: 'a7abd599ae7a9f16667dc680c7f396df',
  helloSignProd: false,
  onboardingHelloSignClientId: '6d1d158458837821fdb0abd650fec878',

  /* eProtect */
  eprotectIframeJavascriptSrc: 'https://request.eprotect.vantivpostlive.com/eProtect/js/eProtect-iframe-client.min.js',

  /* Public Time Server - Worldtimeapi*/
  timeServerApiUrl: 'https://worldtimeapi.org/api/timezone/Etc/UTC',

  /* values below are required due to how environment files work but should NOT have a value */
  personas: undefined,
  securityIdentityServerUrl: undefined,
  clientScope: undefined,
  clientSecret: undefined
};
