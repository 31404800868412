<div class="set-password">

  <div *ngIf="loading" class="text-center mb-5">
    Validating...
    <br /><img src="/assets/images/spinner_squares_circle.gif" alt="Validating..." />
  </div>

  <form *ngIf="displayForm" [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
    <h4 class="text-center w-100 p-2">Set a new password</h4>
    <div>
      <label class="form-label">Email address</label>
      <input class="form-control revert" type="email" autocomplete="off" formControlName="email"
             [readonly]="emailLocked" />
      <form-errors [input]="passwordForm?.get('email')"></form-errors>
    </div>
    <div *ngIf="useResetCode">
      <div>
        <label class="form-label">Reset Code</label>
        <input class="form-control revert" type="password" autocomplete="off" formControlName="resetCode" />
        <form-errors [input]="passwordForm?.get('resetCode')"></form-errors>
      </div>
    </div>
    <div>
      <label class="form-label">New Password</label>
      <input class="form-control revert" type="password" autocomplete="new-password" formControlName="passwordOne" />
      <form-errors [input]="passwordForm?.get('passwordOne')"></form-errors>
    </div>
    <div class="mb-4">
      <label class="form-label">Confirm Password</label>
      <input class="form-control revert" type="password" autocomplete="new-password" formControlName="passwordTwo" />
      <form-errors [input]="passwordForm?.get('passwordTwo')"></form-errors>
    </div>
    <div class="mb-2">
      <h6>Password Requirements</h6>
      <ul>
        <li>Must be at least 12 characters long</li>
        <li>Minimum of 8 unique characters</li>
        <li><em>Can't contain your</em> email address</li>
        <li><em>Can't contain your</em> first name</li>
        <li><em>Can't contain your</em> last name</li>
      </ul>
    </div>
    <div class="mb-1 mt-4 row">
      <button class="btn btn-action col mx-4" type="submit"
              [disabled]="!passwordForm.valid && passwordForm.touched || formSubmitted">
        <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm" role="status"></span>
        Set password
      </button>
    </div>
  </form>

  <div *ngIf="!displayForm && formSubmitted">
    <div class="alert alert-warning">
      <p class="fw-bold">Password updated</p>
      <p>Your password was successfully updated. Be sure to update any password managers you use.</p>
    </div>
    <div class="mb-1 mt-5 row">
      <button type="button" class="btn btn-action col mx-4" (click)="redirectToLogin()">
        Continue to login
      </button>
    </div>
  </div>

  <div class="links text-center w-100">
    For assistance contact RPS Customer Care<br />
    <a class="d-block" href="phone:1-866-582-2547">1-866-582-2547</a>
    <a class="d-block" href="mailto:customercare@rpsins.com">customercare&#64;rpsins.com</a>
  </div>

  <div class="links text-center w-100">
    <a class="d-block p-3" [routerLink]="['/']">Show Login Options</a>
  </div>
</div>