import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Debug, NotificationService, ProfileService } from '@app/core';
import { ApiErrorOptions, ApiErrorService } from '@app/core/api-error.service';
import { AccountService, PasswordChangeTokenRequest, SetPasswordRequest } from '../account.service';

@Component({
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  formSubmitted = false;
  displayForm = false;
  emailLocked = false;
  loading = false;
  useResetCode = false;
  private token: string; // PW reset token

  constructor(
    private readonly form: FormBuilder,
    private readonly error: ApiErrorService,
    private readonly api: AccountService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly notify: NotificationService,
    private readonly profile: ProfileService
  ) { }

  async ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');

    if (this.token === null) {
      // Show username and reset code
      this.createResetForm(true);
      this.displayForm = true;
    } else {
      this.createResetForm();
      this.loading = true;
      var accessToken = await this.validateToken();
      this.loading = false;

      if (accessToken != null) {
        this.displayForm = true;
      } else {
        await this.redirectToReset();
      }
    }
  }

  protected async onSubmit() {
    if (!this.passwordForm.valid) {
      return;
    }

    // get the access token and verify that it is still active
    const accessToken = await this.validateToken();
    if (accessToken == null) {
      await this.redirectToReset();
      return;
    }
    const setDetails: SetPasswordRequest = {
      email: this.passwordForm.get('email').value,
      password: this.passwordForm.get('passwordOne').value,
      token: this.token,
      resetCode: this.useResetCode ? this.passwordForm.get('resetCode').value : null
    };
    this.formSubmitted = true;

    try {
      const response = await this.api.setPassword(setDetails, accessToken.accessToken);
      Debug.debug('[SetPassword:setPassword] Response:', response);
    } catch (e) {
      const error = e as HttpErrorResponse;
      let message = 'Error setting your new password, please contact Customer Service for assistance';
      if (error.error == 'password_reused') {
        message = 'This password has already been used. Please enter a unique password';
      }
      const errorOptions: ApiErrorOptions = {
        message: message,
        title: 'Password update failed'
      };
      this.formSubmitted = false;
      this.error.processError(e, 'setPassword', errorOptions);
      return;
    }

    Debug.debug('[SetPassword] Password updated successfully');
    this.displayForm = false;
  }

  private createResetForm(useResetCode: boolean = false) {
    const email = this.route.snapshot.paramMap.get('email');
    this.useResetCode = useResetCode;
    this.emailLocked = !useResetCode && String.isNotEmpty(email);

    const formControls = {
      email: [useResetCode ? '' : email, [Validators.required, Validators.email]],
      passwordOne: ['', [Validators.required, Validators.minLength(12)]],
      passwordTwo: ['', [Validators.required, Validators.minLength(12)]],
    };

    if (useResetCode) {
      formControls['resetCode'] = ['', Validators.required];
    }

    this.passwordForm = this.form.group(formControls, { validators: [this.confirmPassword] });
  }

  private confirmPassword(group: AbstractControl) {
    const passwordOne = group.get('passwordOne');
    const passwordTwo = group.get('passwordTwo');

    if (!passwordOne || !passwordTwo) {
      return null;
    }

    if (passwordOne.value !== passwordTwo.value) {
      // Set the mustMatch error if passwords do not match and there are no other errors
      if (!passwordTwo.errors || Object.keys(passwordTwo.errors).length === 0) {
        passwordTwo.setErrors({ mustMatch: 'Passwords' });
      }
    } else {
      // Clear the mustMatch error if passwords match
      passwordTwo.setErrors(null);
    }
  }

  private async validateToken() {

    const email = this.passwordForm.get('email').value;
    const tokenValue = this.useResetCode ? this.passwordForm.get('resetCode').value : this.token;

    const request: PasswordChangeTokenRequest = {
      email: email,
      token: tokenValue
    };

    try {
      const response = await this.api.validateToken(request);
      Debug.debug('[SetPassword:validateToken] Response:', response);
      return response;
    } catch (e) {
      Debug.error('Token validation failure', e);
      return null;
    }
  }

  private async redirectToReset(useResetCode: boolean = false) {

    let message;

    if (useResetCode) {
      message = 'The password reset link you clicked has expired. Please fill out the form again to receive a new link.\n\n';
    } else {
      message = 'Your email address or reset code was incorrect or the reset code has expired.\n\n';
    }

    message += 'Closing this modal will return you to the password reset page.';

    await this.notify.showModalMessage(
      'Link expired',
      message,
    );

    const email = this.passwordForm?.get('email').value ?? this.route.snapshot.paramMap.get('email');
    let route = '/reset-password';
    if (email && email.length) {
      route += encodeURIComponent(email);
    }
    return this.router.navigateByUrl(route);
  }

  redirectToLogin() {
    this.profile.redirectToLogin(false, true);
  }
}